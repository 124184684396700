// ==========================================================================
//
// DEFINITION LISTS
//
// Description: styles for definitions lists (enhanced Boostrap defaults)
//
// ==========================================================================


// ==========================================================================
// # SPACED
// ==========================================================================

// # FULL
// ==========================================================================
.dl-spaced {
	dd {
	  margin-bottom: 20px;
	}	
}

// # HALF SPACED
// ==========================================================================
.dl-half-spaced {
	dd {
	  margin-bottom: 10px;
	}	
}


// ==========================================================================
// # HORIZONTAL WRAP
// ==========================================================================

.dl-horizontal--wrap {
	dt {
	  white-space: normal;
	}	
}