// ==========================================================================
//
// TABLE ENHANCED
//
// Description: styles for "enhanced" tables controlled by Datatables JS
//
// ==========================================================================


// ==========================================================================
// # COLUMN HIGHLIGHTING
// ==========================================================================

// needs to be very specific to due overly verbose libary selectors
.table-enhanced > thead > tr {
	> th.header {
		font-weight: bold;
		border-bottom-color: #1CA8DD;
	}
}