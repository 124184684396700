// ==========================================================================
//
// BANNER
//
// Description: 
//
// ==========================================================================

// ==========================================================================
// # BANNER
// ==========================================================================

.banner {
	background-color: @brand-secondary;
	color: #fff;
}

.banner a {
	color: #fff;

	&:hover,
	&:focus {
		color: rgba(255,255,255,.7);
	}
}

// # MODIFIER: ADMIN
// ==========================================================================
.banner--admin {
	background-color: @brand-tertiary;
}


// ==========================================================================
// # LOGO
// ==========================================================================
.site-logo {
	display: block;
	padding-top: 10px;
	padding-bottom: 8px;

	&:hover,
	&:focus {
		opacity: 0.8;
	}

	svg {
		height: 27px;
	}
}


/* ==========================================================================
   # NAVBAR
   ========================================================================== */

.nav-primary > li > a {
	text-transform: uppercase;
	font-size: 0.85em;
}



// ==========================================================================
// # UTILITY NAV
// ==========================================================================

.nav-utility > li > a {
	text-transform: uppercase;
	font-size: 0.85em;
}
