// ==========================================================================
//
// TAB
//
// Description: Styles for the acrhive page only
//
// ==========================================================================


// ==========================================================================
// # BASE
// ==========================================================================

.tab-pane {
	border: 1px solid @nav-tabs-border-color;
	border-top: 0;
	padding: 20px;
}

// ==========================================================================
// # MODIFIER: NO PADDING
// ==========================================================================

.tab-pane--no-padd {
	padding: 0;
}