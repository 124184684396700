@charset "UTF-8"; // must be the first item in the file
// ==========================================================================
//
// MAIN FILE
//
// Description: main entry point for the site styles. Organised on ITCSS
// principles with each layer representing a greater level of specificity
// than the last.
//
// Despite being LESS based this follows guidelines laid out at
// http://sass-guidelin.es/#main-file
//
// ==========================================================================







// FRAMEWORK
@import "./toolkit-light.less";

// ABSTRACTS
@import "./app/abstracts/fonts.less";


body {
	.wf-proxima-normal-loaded & {
		font-family: "ProximaNova", Arial, "Helvetica Neue", Helvetica, sans-serif;
	}
}


// COMPONENTS
@import "./app/components/definition-lists.less";
@import "./app/components/treeview-list.less";
@import "./app/components/tabs.less";
@import "./app/components/tables-enhanced.less";
@import "./app/components/hire-agreement.less";

// LAYOUT
@import "./app/layout/banner.less";

// PAGES
@import "./app/pages/login.less";

// VENDORS
@import "./vendor/select2/select2.less";
@import "./vendor/select2/select2-bootstrap.less";
@import "./vendor/fileinput/fileinput.less";
@import "./vendor/bootstrap-wysiwyg/bootstrap-wysiwyg.less";
@import "./vendor/datatables/datatables.less";

.employer-order-form {
	display: inline;
}