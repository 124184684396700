// ==========================================================================
//
// PAGE: LOGIN
//
// Description: styles for the login page
//
// ==========================================================================

.form-login {	
	margin: 0 auto;
	
	@media (min-width: @screen-xs-min) {
		width: 75%;
	}

	@media (min-width: @screen-sm-min) {
		width: 35%;
	}
}

.form-login__heading {
	text-align: center;
}

.form-login__logo {
	display: block;
	margin: 0 auto;
}