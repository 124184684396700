.well.agreement-box {
  max-height: 40em;
  height: 40em;
  overflow: scroll;
}

.agreement-page {
  margin-bottom: 4em;

  label.error {
    color: red;
  }
}