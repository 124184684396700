
@font-face {
font-family: "ProximaNova";
font-style: normal;
font-weight: normal;
src: url("../fonts/proxima-nova/regular.woff2") format("woff2"), url("../fonts/proxima-nova/regular.woff") format("woff"); }

/*
@font-face {
font-family: "ProximaNova";
font-style: normal;
font-weight: bold;
src: url("../fonts/proxima-nova/bold.woff2") format("woff2"), url("../fonts/proxima-nova/bold.woff") format("woff"); }

*/
@font-face {
font-family: "ProximaNova";
font-style: normal;
font-weight: 600;
src: url("../fonts/proxima-nova/semi.woff2") format("woff2"), url("../fonts/proxima-nova/semi.woff") format("woff"); }

/*
@font-face {
  font-family: "F37_Ginger";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/f37_ginger/bold.woff2") format("woff2"), url("../fonts/f37_ginger/bold.woff") format("woff"); }

*/
