// ==========================================================================
//
// TREE LISTS
//
// Description: styles for tree view
//
// ==========================================================================

.treeview {
	margin-left: 0;
	padding-left: 0;
}

.treeview,
.treeview ul,
.treeview ul ul {
	margin-top: 1em;
	list-style: none;

	li {
		margin-bottom: 1em;
	}

	.glyphicon {
		margin-right: 0.5em;
	}
}

.treeview ul {

	li {
		margin-bottom: 0.5em;
	}
	
}